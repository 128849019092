import { Color } from "src/app/core/models/color.model";

export abstract class PriceService {
  abstract getPriceByBudget(budgetId: number): Promise<PriceReponse>;
  abstract getPriceByBudgetOptimization(
    budgetId: number,
    applicantUserId: number
  ): Promise<OptimizationPrice>;
}

export interface PriceReponse {
  models: ModelPriceReponse[];
  totalPrice: number;
  optimization: OptimizationPrice;
}

export interface OptimizationPrice {
  profiles: ProfilePriceReponse[];
  totalPrice: number;
}

export interface ModelPriceReponse {
  model: {
    name: string;
    id: number;
  };
  price: number;
  profiles: ProfilePriceReponse[];
  accesories: AccesoryPriceResponse[];
  count: number;
}

export interface AccesoryPriceResponse {
  accesory: Accessory;
  type: "Pz." | "M." | "B.";
  price: number;
  count: number;
  message?: string;
}

export class Accessory {
  company: number;
  family: string;
  codRef: string;
  denomination: string;
  weight: number;
  cost: number;
  undBox: number;
  color: Color;
}
export interface ProfilePriceReponse {
  profile: {
    code: string;
    description: string;
    id: number;
    colorExt: Color;
    colorInt: Color;
  };
  priceForMeter: number;
  price: number;
  long: number;
  message?: string;
}

export enum ElementType {
  Frame = "Marco",
  Flashing = "Tapajuntas",
  FrameAssociated = "Marco Asociado",
  Crossbar = "Travesaño",
  Aperture = "Apertura",
  Junquillo = "Junquillo",
}
