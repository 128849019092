import { UserRole } from "src/app/modules/shared/types/userRole.type";
import { Client } from "./client.model";

export class User {
  public id: number;
  public name: string;
  public email: string;
  public role: UserRole;
  public credit: number;
  public accessToken: string;
  public cif: string;

  constructor(user: UserObject) {
    this.id = user.id;
    this.name = user.username;
    this.email = user.email;
    this.role = user.role;
    this.credit = user.credit;
    this.accessToken = user.accessToken;
    this.cif = user.cif;
  }

  public isClient(): boolean {
    return this.role === "Cliente";
  }

  public isAdmin(): boolean {
    return this.role === "Admin";
  }

  public isComercial(): boolean {
    return this.role === "Comercial";
  }

  public toClient(): Client {
    const roleIds = {
      Admin: 1,
      Comercial: 2,
      Cliente: 3,
    };

    return new Client({
      id: this.id,
      name: this.name,
      email: this.email,
      credit: this.credit,
      Cif: this.cif,
      companyPhone: "",
      deletedAt: null,
      phone: "",
      role: {
        id: roleIds[this.role],
        name: this.role,
      },
      subcription: null,
    });
  }
}

export interface UserObject {
  id: number;
  username: string;
  email: string;
  role: UserRole;
  credit: number;
  accessToken: string;
  cif: string;
}
