import { TuiRootModule, TuiNotificationsModule } from "@taiga-ui/core";
// CORE
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { LayoutModule } from "@angular/cdk/layout";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeEs from "@angular/common/locales/es";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// TOOLS
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { defineLocale } from "ngx-bootstrap/chronos";
import { esLocale } from "ngx-bootstrap/locale";
import { ModalModule } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
// Components
// Dialogs
// Helpers
import { JwtInterceptor } from "./@helpers/session/jwt-interceptor";
// Pages
// Services
import { LoginService } from "./@services/login.service";
// APP
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApiPriceService } from "./modules/shared/services/api-price.service";
import { PriceService } from "./modules/shared/services/price.service.interface";
registerLocaleData(localeEs);

defineLocale("es", esLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    NgxSliderModule,
    ButtonsModule.forRoot(),
    AccordionModule.forRoot(),

    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    LayoutModule,
    NgOptionHighlightModule,
      TuiRootModule,
      TuiNotificationsModule
],

  exports: [],
  entryComponents: [],
  providers: [
    LoginService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "es" },
    {
      provide: PriceService,
      useClass: ApiPriceService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
