import { UserRole } from 'src/app/modules/shared/types/userRole.type';

export class Client {
  public id: number;
  public name: string;
  public phone: string;
  public companyPhone: string;
  public Cif: string;
  public credit: number;
  public email: string;
  public subcription: Date;
  public deletedAt: Date;
  public role: { id: number; name: UserRole };

  constructor(user: ClientObject) {
    this.id = user.id;
    this.name = user.name;
    this.phone = user.phone;
    this.companyPhone = user.companyPhone;
    this.Cif = user.Cif;
    this.credit = user.credit;
    this.email = user.email;
    this.subcription = user.subcription;
    this.deletedAt = user.deletedAt;
    this.role = user.role;
  }
}

export interface ClientObject {
  id: number;
  name: string;
  phone: string;
  companyPhone: string;
  Cif: string;
  credit: number;
  email: string;
  subcription: Date;
  deletedAt: Date;
  role: {
    id: number;
    name: UserRole;
  };
}
