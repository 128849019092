import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  OptimizationPrice,
  PriceReponse,
  PriceService,
} from "./price.service.interface";

@Injectable({
  providedIn: "root",
})
export class ApiPriceService implements PriceService {
  private _apiUrl = environment.apiUrl + "/prices";

  constructor(private http: HttpClient) {}

  getPriceByBudgetOptimization(
    budgetId: number,
    applicantUserId: number
  ): Promise<OptimizationPrice> {
    const params = new HttpParams().set(
      "applicantUserId",
      applicantUserId.toString()
    );

    return this.http
      .get<OptimizationPrice>(
        `${this._apiUrl}/calculate/optimization/${budgetId}`,
        { params }
      )
      .toPromise();
  }

  getPriceByBudget(budgetId: number): Promise<PriceReponse> {
    return this.http
      .get<PriceReponse>(`${this._apiUrl}/calculate/${budgetId}`)
      .toPromise();
  }
}
