import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { User, UserObject } from "../core/models/user.model";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private messageSource = new BehaviorSubject("");
  currentClient = this.messageSource.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  changeCurrentClient(client: any): void {
    this.messageSource.next(client);
  }

  login(userInfo): Observable<User> {
    return this.http
      .post<{ user: UserObject; token: string }>(
        environment.apiUrl + "/login",
        {
          email: userInfo.user,
          password: userInfo.password,
        }
      )
      .pipe(
        map(({ user, token }) => {
          const userDTO = {
            ...user,
            role: (user.role as any).name,
            accessToken: token,
          };
          this.generateUserData(userDTO);

          return new User(userDTO);
        })
      );
  }

  logout(): void {
    sessionStorage.removeItem("GALISUR-UserData");
    sessionStorage.removeItem("GALISUR-ClientData");

    this.messageSource.next("");
    this.router.navigate(["/auth/login"]);
  }

  getUserToken(): any {
    const userData: UserObject = JSON.parse(
      sessionStorage.getItem("GALISUR-UserData")
    );

    if (userData) {
      return userData.accessToken;
    }
  }

  getUserData(): User {
    const userData: any = JSON.parse(
      sessionStorage.getItem("GALISUR-UserData")
    );
    const user = new User({
      id: userData.id,
      email: userData.email,
      role: userData.role,
      username: userData.name,
      accessToken: userData.accessToken,
      credit: userData.credit,
      cif: userData.cif,
    });

    if (userData) {
      return user;
    }
  }

  generateUserData(authData: any): void {
    
    const prevData: string = sessionStorage.getItem("GALISUR-UserData");

    

    sessionStorage.setItem("GALISUR-UserData", JSON.stringify(authData));
  }

  generateClientSelectedData(authData: any): void {
    const prevData: string = sessionStorage.getItem("GALISUR-ClientData");

    

    sessionStorage.setItem("GALISUR-ClientData", JSON.stringify(authData));
  }

  getClientData(): any {
    const userData: any = JSON.parse(
      sessionStorage.getItem("GALISUR-ClientData")
    );

    if (userData) {
      return userData;
    }
  }
}
